<template>
  <div class="shared-checkbox" :class="[props.modelValue && 'shared-checkbox--checked']">
    <label :for="CHECKBOX_ID" class="shared-checkbox__label">
      <input
        :id="CHECKBOX_ID"
        :checked="props.modelValue"
        type="checkbox"
        class="shared-checkbox__input"
        @change="emits('update:modelValue', ($event.target as HTMLInputElement)!.checked)"
      />
      <span class="shared-checkbox__box">
        <LazySvgoCheckmarkIcon class="shared-checkbox__box_icon" />
      </span>
      <span v-if="$slots.prependText" class="shared-checkbox__prepend">
        <slot name="prependText" />
      </span>
      <SharedText
        v-if="props.text"
        as="span"
        :weight="FontWeights.BOLD"
        :size="changeXl(FontSizes.MEDIUM, FontSizes.SMALL_XS)"
        :color="Colors.NEUTRAL.WHITE"
        class="shared-checkbox__text"
        v-bind="props.textProps"
      >
        {{ props.text }}
      </SharedText>
      <span v-if="$slots.appendText" class="shared-checkbox__prepend">
        <slot name="appendText" />
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
import type { IEmitsSharedCheckbox, IPropsSharedCheckbox } from './SharedCheckbox.types';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';

const CHECKBOX_ID = useId();

const changeXl = GlobalUtils.Media.changeByMedia('xl');

const emits = defineEmits<IEmitsSharedCheckbox>();
const props = withDefaults(defineProps<IPropsSharedCheckbox>(), {
  textProps: () => ({}),
});

const colors = computed(() => ({
  activeBgColor: Array.isArray(props.activeBackgroundColor)
    ? props.activeBackgroundColor[0]
    : props.activeBackgroundColor,
  bgColor: Array.isArray(props.backgroundColor) ? props.backgroundColor[0] : props.backgroundColor,
}));
</script>

<style lang="scss" scoped>
.shared-checkbox {
  --checkbox-default-color: v-bind('colors.bgColor');
  --checkbox-active-color: v-bind('colors.activeBgColor');
}
</style>
<style src="./SharedCheckbox.scss" lang="scss" scoped></style>
